import axios from 'axios';
import { config } from '../config';
import {auth} from "@/firebase.config";

export async function createUser(data) {
  const host = `${config.service.host}/api`;

  const response = await axios.post(
    `${host}/user`,
    {
      data
    },
    {
      headers: {
        //Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}


export async function getUser() {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
        `${host}/user/` + auth.currentUser.uid,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}

export async function updateUser(data, dob) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const year = dob.getUTCFullYear();
    const month = String(dob.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(dob.getUTCDate()).padStart(2, '0');
    const mysqlDate = `${year}-${month}-${day}`;
    data.dob = mysqlDate;
    console.log('...data', data)
    const response = await axios.put(
        `${host}/user/` + auth.currentUser.uid,
        {
            data,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}


