import {createWebHistory, createRouter} from "vue-router";
import Home from "@/views/HomePage.vue";
import CreateAccount from "@/views/LogIn/CreateAccount.vue";
import FAQ from "@/views/FAQ.vue";
//import FindInstructors from "@/views/FindInstructors/FindInstructors.vue";
//import Instructor from "@/views/FindInstructors/Instructor.vue";
import Program from "@/views/Program.vue";
import Resources from "@/views/Resources.vue";
import AboutUs from "@/views/AboutUs.vue";
import ContactUs from "@/views/ContactUs.vue";
import StudentPage from '@/views/MyPage.vue';
import {auth} from '@/firebase.config'
import FindStudents from "@/views/FindStudents/FindStudents.vue";
import Student from "@/views/FindStudents/Student.vue";
import NonDiscriminatoryPolicy from "@/views/NonDiscriminatoryPolicy.vue";
import SupportUs from "@/views/SupportUs.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        props: true
    },
    {
        path: '/student/:id',
        name: "Student",
        component: Student,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/create-account",
        name: "CreateAccount",
        component: CreateAccount,
        props: true
    },
    {
        path: "/profile",
        name: "StudentPage",
        component: StudentPage,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/faq",
        name: "FAQ",
        component: FAQ,
    },
    {
        path: "/find-students",
        name: "FindStudents",
        component: FindStudents,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/program",
        name: "Program",
        component: Program,
        props: true
    },
    {
        path: "/resources",
        name: "Resources",
        component: Resources,
        props: true
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
        props: true
    },
    {
        path: "/contact",
        name: "ContactUs",
        component: ContactUs,
        props: true
    },
    {
        path: "/nondiscriminatory-policy",
        name: "NonDiscriminatoryPolicy",
        component: NonDiscriminatoryPolicy,
        props: true
    },
    {
        path: "/supportus",
        name: "Support Us",
        component: SupportUs,
        props: true
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from) => {
    const currentUser = auth.currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    //console.log("...currentUser", currentUser)

    if (requiresAuth) {
        if (currentUser) {
            if (currentUser.emailVerified) {
                if (from.path === '/create-account') {
                    return {name: 'Home'}
                }
                return true;
            } else {
                if (to.path === '/find-students') {
                    alert('Please verify your email to access this page.')
                    return {name: 'Home'}
                }
                return true;
            }
        } else {
            alert('Please log in to access this page.')

            return {name: 'Home'}
        }
    } else {
        return true;
    }

});
export default router;