<template>
  <div class="onr-container">
    <Banner></Banner>
    <Navigation></Navigation>

  </div>
  <router-view></router-view>
  <Footer></Footer>
</template>

<script>
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/400-italic.css";

import Banner from './components/banner.vue';
import Navigation from './components/navigation.vue'
import Footer from './components/footer.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    Banner,
    Footer,
  },
  methods: {

  },
  mounted() {
  },
  computed: {
  },
}
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.onr-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.onr__main {
  &-container {
    display: flex;
    margin: 0;
  }

  &-photo {
    width: 100%;
  }
}
</style>
