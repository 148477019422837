import { createApp } from 'vue'
import CarbonVue3 from '@carbon/vue';
import App from './App.vue';
import router from './components/router';
import store from './store';
import {auth} from './firebase.config';
import * as Vue from "vue-plugin-load-script";
let app;
Vue.loadScript("https://maps.googleapis.com/maps/api/js")
const unsubscribe = auth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App).use(store).use(CarbonVue3).use(router).mount('#app');
    }
    unsubscribe();
});


