import {config} from "@/config";
import axios from "axios";
import {auth} from "@/firebase.config";
export async function createNullReport(cid, uid, name, email, sid, studentName, subject) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const data = {
        cid: cid,
        no: 0,
        uid: uid,
        name: name,
        email: email,
        form: "",
        sid: sid,
        studentName: studentName,
        subject: subject,
    }
    console.log(data);
    const response = await axios.post(
        `${host}/reports/?uid=${auth.currentUser.uid}`,
        {
            data
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}


export async function createReport(cid, no, uid, name, email, form, sid, studentName, subject) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const data = {
        cid: cid,
        no: no,
        uid: uid,
        name: name,
        email: email,
        form: form,
        sid: sid,
        studentName: studentName,
        subject: subject,
    }
    console.log(data);
    const response = await axios.post(
        `${host}/reports/?uid=${auth.currentUser.uid}`,
        {
            data
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}


export async function getReports(cid) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
        `${host}/reports/${cid}?uid=${auth.currentUser.uid}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}
export async function getReport(cid, rid) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
        `${host}/reports/view/${cid}?uid=${auth.currentUser.uid}&rid=${rid}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}

export async function getReportQuestions(week) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
        `${host}/util/report_questions/${week}?uid=${auth.currentUser.uid}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}