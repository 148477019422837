import axios from 'axios';
import {config} from '../config';
import {auth} from "@/firebase.config";

export async function sendAdminEmail(data) {
    const host = `${config.service.host}/api`;
    const response = await axios.post(
        `${host}/util/email`,
        {
            data
        },
        {}
    );
    return response.data;
}

export async function sendMsg(to, msg) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.post(
        `${host}/util/msg?uid=${auth.currentUser.uid}`,
        {
            data:{to: to, message: msg, name: auth.currentUser.displayName}
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}
