<template>
  <!-- <div class="onr-home onr-home__hero">
    <Carousel></Carousel>
  </div> -->
  <div class="onr-home onr-home__hero">
      <img class="onr-home__hero__image" src="../assets/main_v2_02.png"/>
    </div>
  <div class="onr-home onr-home__panels">
    <div class="onr-home onr-home onr-home__program onr-home__block onr-home__panel--main">
      <h1 class="onr-home onr-home__heading">Our program</h1>
      <h3 class="onr-home onr-home__subheading">Interactive Virtual One-to-One Learning</h3>
      <p class="onr-home onr-home__description">Welcome to The Dream, where education knows no bounds! At the heart of our mission lies a commitment to education equity, transcending barriers of location, gender, and religion. Introducing our Interactive Virtual One-to-One Learning platform, designed to revolutionize the way we learn. Say goodbye to one-size-fits-all education and embrace a personalized journey tailored to your unique needs. With live, one-on-one teaching sessions, we're not just shaping minds; we're cultivating a future where every learner can thrive. Join The Dream, where education meets individuality, and let's unlock boundless possibilities together.
      </p>
    </div>
    <template
      v-for="(program, index) in programs"
      :key="index"
    >
      <div class="onr-home onr-home__block onr-home__panel">
        <div class="onr-home onr-home__container__image">
          <img class="onr-home onr__stock-photo" :src="program.image"/>
        </div>
        <div class="onr-home onr-home__container__text">
          <h1 class="onr-home onr-home__heading">{{program.title}}</h1>
          <h3 class="onr-home onr-home__subheading">{{program.subtitle}}</h3>
          <p class="onr-home onr-home__description--sub">{{program.description}}</p>
          <!-- <button class="onr-home__button" @click="findLBLInstructors()">View more</button> -->
        </div>
      </div>
    </template>
  </div>
  <!-- <div class="onr-body__testimony">
    <h1 class="onr-body__testimony-title">Our testimonials</h1>
    <span class="onr-body__testimony-subheading">What students say about us</span>
  </div>
  <QuestionBlock></QuestionBlock> -->

</template>

<script>
// import ImageUploader from 'vue-image-upload-resize';
// import VueCoreImageUpload from 'vue-core-image-upload';
// import QuestionBlock from '../blocks/questionBlock.vue';
// import Carousel from '../components/carousel.vue';
import programs from '../data/programs.json';

export default {
  name: 'HomePage',
  components: {
    // VueCoreImageUpload,
    // QuestionBlock,
    // Carousel
  },
  data() {
		return {
			submission: {
        firsname: String,
        lastname: String,
        email: String,
        phone: String,
        question: String,
      }
    };
  },
  computed: {
    programs() {
			return programs;
		},
  },
  mounted() {
    this.programs;
  },
  methods: {
    findSBSInstructors() {
      this.$router.push({
        name: 'FindInstructors',
        params: {
          subject: 'english',
        },
      });
    },
    findLBLCodingInstructors() {
      this.$router.push({
        name: 'FindInstructors',
        params: {
          subject: 'coding',
        },
      });
    },
    findLBLOfficeInstructors() {
      this.$router.push({
        name: 'FindInstructors',
        params: {
          subject: 'office',
        },
      });
    },
    findKInstructors() {
      this.$router.push({
        name: 'FindInstructors',
        params: {
          subject: 'korean',
        },
      });
    }
  }
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.onr {
  &-home {
    &__block {
      display: flex;
      margin: 5rem;
    }

    &.onr-home__panel {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media only screen and (max-width: 959px) {
        .onr-home__container__image {
          flex: 1 0 50%;
        }
      }

      @media only screen and (max-width: 959px) {
        .onr-home__container__text {
          flex: 0 1 100%;
        }
      }

      .onr-home__container__text {
        margin-left: 7rem;
      }

      &:nth-child(3) {
        flex-direction: row-reverse;

        .onr-home__container__text {
          margin-left: unset;
          margin-right: 7rem;
        }
      }
    }

    &__hero {
      display: flex;
      margin: 0;
      margin-top: 105.76px;

      &__image {
        width: 100%;

        @media only screen and (max-width: 770px) {
          object-fit: cover;
          height: 14rem;
          vertical-align: middle;
        }
      }
    }

    &__panels {
      background-color: $neutral-1;
      padding-bottom: 1px;
      padding-top: 7rem;
    }

    &__panel {
      &--main {
        display: block;
        text-align: left;
        margin: 0 5rem 10rem 5rem;
      }

      &--first {
        margin-top: 200px;
      }
    }

    &__button {
      background-color: unset;
      font-size: 18px;
      border: solid 3px black;
      font-weight: 400;
      margin-top: 30px;
      padding: 13px 15px;
      text-transform: uppercase;

      &:hover {
        background-color: $blue-1;
        border: solid 3px $blue-1;
        color: white;
        cursor: pointer;
      }
    }

    &__heading {
      font-size: 3em;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
      line-height: 1.2;
      text-transform: uppercase;
      
      @media only screen and (max-width: 770px) {
        font-size: 2em;
      }
    }
    
    &__subheading {
      color: $blue-2;
      margin: 0;
      font-size: 2em;
      font-weight: 400;

      @media only screen and (max-width: 770px) {
        font-size: 1.2em;
      }
    }

    &__description {
      font-size: 1.3em;
      font-weight: 400;

      @media only screen and (max-width: 770px) {
        font-size: 1em;
      }

      &--sub {
        font-size: 1.2em;
        font-weight: 500;

        @media only screen and (max-width: 770px) {
          font-size: 1em;
        }
      }
    }

    &__container {
      &__text {
        text-align: left;
        width: 100%;
      }

      &__image {

        .onr__stock-photo {
          border-radius: 50%;
          height: 25em;
          object-fit: cover;
          width: 25em;
        }
      }
    }
  }

  &__testimony {
    &-title {
      color: black;
      font-size: 40px;
      margin: 0;
      padding-top: 40px;
      text-transform: uppercase;
    }

    &-subheading {
      margin-top: 5px;
    }
  }

  &__quote {
    background-color: #848484;
    height: 300px;
    margin: 200px 0 100px;

    &-text {
      color: white;
      font-size: 30px;
      font-family: Optima, sans-serif;
      padding: 100px 230px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 770px) {
  .onr-home.onr-home__panels {
    padding-top: 2rem;

    .onr-home.onr-home__block {
      margin: 0 2rem 5rem 2rem;

      &.onr-home__panel {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        width: 100%;

        .onr-home.onr-home__container__text {
          margin: 2rem;
        }

        .onr-home.onr-home__container__image {
          .onr__outline {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .onr-home.onr-home__panels {
    .onr__stock-photo {
      width: 14em;
      height: 14em;
    }
  }
}
</style>