<template>
	<div class="onr-accordion">
    <div @click="toggle()" class="onr-accordion-entry" :class="{ 'entry--open': toggleContent }">
			<span clspanss="accordion-question">
				{{question}}
			</span>
			<span class="accordion-chevron__down" :class="{ 'accordion-chevron__up' : toggleContent}">
				<svg width="24px" height="24px" viewBox="0 0 24 24" fill="none">
					<path d="M19.1416 9.92896L12.7776 16.2929C12.3871 16.6834 11.7539 16.6834 11.3634 16.2929L4.99946 9.92895" stroke="#000000" stroke-linecap="round"/>
				</svg>
			</span>
    </div>
		<div v-if="toggleContent" class="onr-accordion-slidedown">
			<p class="accordion-content">
				{{content}}
			</p>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Accordion',
  props: {
		question: String,
		content: String,
  },
	data: () => {
		return {
			toggleContent: false,
		};
	},
	mounted() {
  },
  methods: {
		toggle() {
			this.toggleContent = !this.toggleContent;
		}
  }
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.onr-accordion {
	.onr-accordion {
		&-entry {
			display: flex;
			flex-direction: row;
			background-color: #eee;
			color: #444;
			cursor: pointer;
			padding: 15px 15px 8px;
			border: none;
			text-align: left;
			outline: none;
			font-size: 15px;
			transition: 0.4s;
			margin: 5px 30px;

			&.entry--open {
				background-color: $blue-1;
				color: white;

				.accordion-chevron__up {
					svg {
						fill: white;
					}
				}
			}

			.accordion {
				&-question {
					font-size: 17px;
				}

				&-chevron {
					&__down {
						display: inline-block;
						margin-left: auto;

						&.accordion-chevron__up {
							svg {
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}

		&-slidedown {
			padding: 0 18px;
			display: block;
			background-color: white;
			overflow: hidden;

			.accordion {
				&-content {
					color: $grey-3;
					font-size: 13px;
					margin: 20px;
					text-align: left;
				}
			}
		}
	}
}

</style>