import {config} from "@/config";
import axios from "axios";
import {auth} from "@/firebase.config";

export async function createClass(tid, teacherName, sid, studentName, subject, schedule) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const data = {
        tid: tid,
        teacherName: teacherName,
        sid: sid,
        studentName: studentName,
        subject: subject,
        schedule: schedule,
    }
    console.log(data);
    const response = await axios.post(
        `${host}/classes/?uid=${auth.currentUser.uid}`,
        {
            data
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}


export async function getMyClass(role) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
        `${host}/classes/?uid=${auth.currentUser.uid}&role=${role}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}
export async function getClassHistory(role) {
    const host = `${config.service.host}/api`;
    const accessToken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
        `${host}/classes/all?uid=${auth.currentUser.uid}&role=${role}`,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
    );
    return response.data;
}