<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="onr-supportus onr-supportus__panels" id="supportus">
    <div class="onr-supportus onr-supportus__block onr-supportus__panel--main">
      <!--<h1 class="onr-supportus onr-supportus__heading">Our program</h1>-->
      <h3 class="onr-supportus onr-supportus__subheading">The Bridge to Dreams</h3>
      <p class="onr-supportus onr-supportus__description">Empower underprivileged students globally by providing them with language and computer skills, enhancing their employment prospects, supporting their dreams, and fostering the belief that education is key to sustainable change.
<br><br></p>
        <div  class="onr-supportus onr-supportus__description">Zelle: finance@thedreaminc.org<br><br></div>
        <div  class="onr-supportus onr-supportus__description" style="display: flex; align-items: center;">PayPal:
          <a href="https://www.paypal.com/donate/?hosted_button_id=FXXKR39S9JS3N" target="_blank">
            <img src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" align="top" alt="Donate with PayPal button" style="margin-left: 10px;" /></a><br><br></div>
<p  class="onr-supportus onr-supportus__description">
        Your 100% tax-deductible donation helps provide essential education, opening doors to new opportunities and brighter futures. You can make a difference today.<br><br>
        We are exempt from federal income tax under Internal Revenue Code Section 501(c)(3) as a non-profit organization. As such, your contribution is tax-deductible to the extent allowed by law, and in accordance with applicable regulations. No goods or services were provided in exchange for your generous donation. Thank you for your contribution to The Dream Inc.
</p>

    </div>

  </div>
  <!-- <QuestionBlock></QuestionBlock> -->
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Support Us',
  components: {
    // QuestionBlock,
  },
  data() {
		return {
    };
  },
  mounted() {

    window.scrollTo({top: 0, behavior: 'smooth'});
  },
  computed: {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../utils/_colors.scss';

.onr {
  &-supportus {
    &__block {
      display: flex;
      margin: 5rem;
    }

    &.onr-supportus__panel {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media only screen and (max-width: 959px) {
        .onr-supportus__container__image {
          flex: 1 0 50%;
        }
      }

      @media only screen and (max-width: 959px) {
        .onr-supportus__container__text {
          flex: 0 1 100%;
        }
      }

      .onr-supportus__container__text {
        margin-left: 7rem;
      }

      &:nth-child(3) {
        flex-direction: row-reverse;

        .onr-supportus__container__text {
          margin-left: unset;
          margin-right: 7rem;
        }
      }
    }

    &__hero {
      margin: 0;
      margin-top: 105.76px;

      &__image {
        margin: 0;
        width: 100%;

        @media only screen and (max-width: 770px) {
          object-fit: cover;
          height: 14rem;
          vertical-align: middle;
        }
      }
    }

    &__panels {
      background-color: $neutral-1;
      padding-bottom: 1px;
      padding-top: 7rem;

    }

    &__panel {
      &--main {
        display: block;
        text-align: left;
        margin: 5rem 5rem 10rem 5rem;

      }

      &--first {
        margin-top: 200px;
      }
    }

    &__heading {
      font-size: 3em;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
      line-height: 1.2;
      text-transform: uppercase;

      @media only screen and (max-width: 770px) {
        font-size: 2em;

      }
    }

    &__subheading {
      color: $blue-2;
      margin: 0;
      font-size: 2em;
      font-weight: 400;

      @media only screen and (max-width: 770px) {
        font-size: 1.2em;
        margin-top: 150px;
      }
    }

    &__description {
      font-size: 1.3em;
      font-weight: 400;

      @media only screen and (max-width: 770px) {
        font-size: 1em;
      }

      &--sub {
        font-size: 1.2em;
        font-weight: 500;

        @media only screen and (max-width: 770px) {
        font-size: 1em;
      }
      }
    }

    &__container {
      &__text {
        text-align: left;
        width: 100%;
      }

      &__image {
        // position: relative;

        .onr__outline {
          border: 3px solid $blue-1;
          border-radius: 50%;
          height: 422px;
          -o-object-fit: cover;
          object-fit: cover;
          width: 422px;
          position: absolute;
          left: -14px;
          top: -14px;  
        }

        .onr__stock-photo {
          border-radius: 50%;
          height: 25em;
          object-fit: cover;
          width: 25em;
        }
      }
    }
  }

  &__testimony {
    &-title {
      color: black;
      font-size: 40px;
      margin: 0;
      padding-top: 40px;
      text-transform: uppercase;
    }

    &-subheading {
      margin-top: 5px;
    }
  }

  &__quote {
    background-color: #848484;
    height: 300px;
    margin: 200px 0 100px;

    &__text {
      color: white;
      font-size: 30px;
      font-family: Optima, sans-serif;
      padding: 100px 230px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 770px) {
  .onr-supportus.onr-supportus__panels {
    padding-top: 2rem;

    .onr-supportus.onr-supportus__block {
      margin: 0 2rem 5rem 2rem;

      &.onr-supportus__panel {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        width: 100%;

        .onr-supportus.onr-supportus__container__text {
          margin: 2rem;
        }

        .onr-supportus.onr-supportus__container__image {
          .onr__outline {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .onr-supportus.onr-supportus__panels {
    .onr__stock-photo {
      width: 14em;
      height: 14em;
    }
  }
}
</style>