<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div v-show="showMenuPanel" class="onr-navigation__menu-panel">
    <div class="onr-navigation__items mobile-view">
      <button class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/about-us" @click="closePanel()">About Us</router-link>
      </button>
      <button class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/program" @click="closePanel()">Program</router-link>
      </button>
      <button v-if="loggedIn" class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/find-students" @click="closePanel()" >Find a Student</router-link>
      </button>

      <button class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/supportus" @click="closePanel()">Support Us</router-link>
      </button>
      <button class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/faq" @click="closePanel()">FAQ</router-link>
      </button>
      <div class="onr-navigation__item divider"></div>
      <button v-if="!loggedIn" class="onr-navigation__item">
        <a class="onr-banner__item--link login-link" @click="openLogin()">Sign In</a>
      </button>
      <template v-else>
        <button class="onr-navigation__item">
          <a class="onr-banner__item--link" @click="navigateToPage(`StudentPage`)">My Page</a>
        </button>
        <button class="onr-navigation__item">
          <a class="onr-banner__item--link" @click="logOut()">LogOut</a>
        </button>
      </template>
    </div>
  </div>
	<ul class="onr-navigation">
    <li class="onr-navigation__item hamburger-menu" @click="toggleMenu()">
      <svg width="25px" height="25px" viewBox="0 0 15 15" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z" fill="#000000"/>
      </svg>
    </li>
    <li class="onr-navigation__item onr-navigation-home" to="/">
      <router-link class="onr-navigation__item--link" to="/"><img class="onr-navigation" src="../assets/thedream_top_logo.png"></router-link>
    </li>
    <div class="onr-navigation__items browser-view">
      <li class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/about-us">About Us</router-link>
      </li>
      <li class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/program">Program</router-link>
      </li>
      <li class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/find-students" >Find a Student</router-link>
      </li>
      <li class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/supportus">Support Us</router-link>
      </li>
      <li class="onr-navigation__item">
        <router-link class="onr-navigation__item--link" to="/faq">FAQ</router-link>
      </li>
    </div>
  </ul>
  <LoginOverlay :showModal="showLoginModal" @close="close"></LoginOverlay>
</template>

<script>
import LoginOverlay from '../components/overlays/loginOverlay.vue';
import {auth} from "@/firebase.config";

export default {
  name: 'Navigation-block',
  components: {
    LoginOverlay,
  },
  props: {
    msg: String
  },
  data: () => ({
    showMenuPanel: false,
    showLoginModal: false,
  }),
  computed: {
    loggedIn() {
      return localStorage.getItem('authenticated') && auth.currentUser;
    },
  },
  methods: {
    navigateToPage(name) {
      this.closePanel();
      this.$router.push({
        name,
      });
    },
    openLogin() {
      this.showLoginModal = true;
      this.closePanel();
    },
    toggleMenu() {
      if (this.showMenuPanel) {
        this.showMenuPanel = false;
      } else {
        this.showMenuPanel = true;
      }
    },
    closePanel() {
      this.showMenuPanel = false;
    },
    logOut() {
      this.$store.dispatch('authStore/logOut').then(() => {
        this.closePanel();
        this.$router.go({
          path: '/',
        });
      }).catch((error) => {
        console.log(error);
      });
    },
    close() {
      this.showLoginModal = false;
    },
  }
}
</script>

<style lang="scss">
@import '../utils/_colors.scss';

.onr-navigation {
  background-color: white;
  display: flex;
  list-style-type: none;
  margin: 0;
  max-height: 4rem;
  overflow: hidden;
  padding: 0;
  padding-top: 5px;
  padding-bottom: 0.8rem;

  &__menu-panel {
    position: absolute;
    background-color: $beige-1;
    width: 90vw;
    height: 100vh;
    margin-top: 4rem;
  }
  
  &__items {
    &.mobile-view {
      min-height: 400px;
      background-color: unset;
      margin-bottom: 4rem;
      text-align: left;
      flex: 1 0 20%;
      padding-top: 1rem;
    }

    &.browser-view {
      display: flex;
      align-items: end;
      white-space: nowrap;
      margin-left: auto;
      order: 2;

      @media only screen and (max-width: 800px) {
        display: none;
      }
    }

    // @media only screen and (max-width: 800px) {
    //   &.browser-view {
    //   }
    // }
  }

  // @media only screen and (max-width: 650px) {
  //   .onr-navigation__items {
  //     display: flex;
  //     width: 480px;
  //     overflow-x: scroll;
  //     overflow-y: hidden;
  //     white-space: nowrap;


  //   }
  // }

  &-home.onr-navigation__item {
    font-size: 22px;
    font-weight: 800;
    padding: 0 3rem 0 0;
    width: 77%;
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 800px) {
      padding: 0 2rem;
      width: unset;
      display: unset;
      justify-content: unset;
    }
  
    &:hover {
      border-bottom: unset;
      color: unset;
    }

    &.active {
      background-color: unset;
      color: unset;
    }
  }

  &__item {
    background-color: unset;
    padding: 1rem 2rem;
    width: 100%;
    font-size: 1.2em;
    border: none;
    cursor: pointer;
    text-align: left;

    &.divider {
      border-top: 1px solid $grey-1;
      width: 80%;
      margin: 1rem 2rem;
      padding: 0;
    }

    &.hamburger-menu {
      position: relative;
      width: unset;

      @media only screen and (min-width: 800px) {
        display: none;
      }
    }

    svg {
      vertical-align: bottom;
    }
    
		.onr-navigation__item--link {
      //text-wrap: nowrap;

			&:link {
				color: black;
				text-decoration: none;
			}

			&:visited {
				color: black;
				text-decoration: none;
			}

			&:hover {
				color: black;
				text-decoration: none;
			}

			&:active {
				color: black;
				text-decoration: none;
			}
		}

    &__menu {
      display: none;
      list-style-type: none;
      margin: 5px 0;
      overflow: hidden;
      padding: 0 14px;
    }
  }

  & .onr-image-container {
    padding: 14px 16px;

    &__profile {
      margin-left: auto;

			.onr-navigation__item--link {
				display: flex;
			}

      &-text {
        font-size: 14px;
        padding-left: 9px;
        margin-right: 10px;
      }
    }

    &:hover {
      background-color: unset;
      border-bottom: unset;
      color: unset;
    }
  }

}

</style>