<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="onr-policy onr-policy__panels" id="policy">
    <div class="onr-policy onr-policy__block onr-policy__panel--main">
      <!--<h1 class="onr-policy onr-policy__heading">Our program</h1>-->
      <h3 class="onr-policy onr-policy__subheading">Nondiscriminatory Policy Notice</h3>
      <p class="onr-policy onr-policy__description">The Dream is committed to providing an inclusive and welcoming environment for all students. We do not discriminate on the basis of race, color, national or ethnic origin, or any other characteristic protected under applicable law in the administration of our educational policies, admissions policies, and other school-administered programs.<br>
        <br>
        For more information about our nondiscriminatory policy, please contact us.
<br><br>
        Email: admin@thedreaminc.org</p>
    </div>

  </div>
  <!-- <QuestionBlock></QuestionBlock> -->
</template>

<script>
// import QuestionBlock from '../blocks/questionBlock.vue';
import programs from '../data/programs.json';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Program',
  components: {
    // QuestionBlock,
  },
  data() {
		return {
    };
  },
  mounted() {
    this.programs;
    window.scrollTo({top: 0, behavior: 'smooth'});
  },
  computed: {
    programs() {
			return programs;
		},
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../utils/_colors.scss';

.onr {
  &-policy {
    &__block {
      display: flex;
      margin: 5rem;
    }

    &.onr-policy__panel {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      @media only screen and (max-width: 959px) {
        .onr-policy__container__image {
          flex: 1 0 50%;
        }
      }

      @media only screen and (max-width: 959px) {
        .onr-policy__container__text {
          flex: 0 1 100%;
        }
      }

      .onr-policy__container__text {
        margin-left: 7rem;
      }

      &:nth-child(3) {
        flex-direction: row-reverse;

        .onr-policy__container__text {
          margin-left: unset;
          margin-right: 7rem;
        }
      }
    }

    &__hero {
      margin: 0;
      margin-top: 105.76px;

      &__image {
        margin: 0;
        width: 100%;

        @media only screen and (max-width: 770px) {
          object-fit: cover;
          height: 14rem;
          vertical-align: middle;
        }
      }
    }

    &__panels {
      background-color: $neutral-1;
      padding-bottom: 1px;
      padding-top: 7rem;

    }

    &__panel {
      &--main {
        display: block;
        text-align: left;
        margin: 5rem 5rem 10rem 5rem;
      }

      &--first {
        margin-top: 200px;
      }
    }

    &__heading {
      font-size: 3em;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
      line-height: 1.2;
      text-transform: uppercase;

      @media only screen and (max-width: 770px) {
        font-size: 2em;
      }
    }

    &__subheading {
      color: $blue-2;
      margin: 0;
      font-size: 2em;
      font-weight: 400;

      @media only screen and (max-width: 770px) {
        font-size: 1.2em;
        margin-top: 150px;
      }
    }

    &__description {
      font-size: 1.3em;
      font-weight: 400;

      @media only screen and (max-width: 770px) {
        font-size: 1em;
      }

      &--sub {
        font-size: 1.2em;
        font-weight: 500;

        @media only screen and (max-width: 770px) {
        font-size: 1em;
      }
      }
    }

    &__container {
      &__text {
        text-align: left;
        width: 100%;
      }

      &__image {
        // position: relative;

        .onr__outline {
          border: 3px solid $blue-1;
          border-radius: 50%;
          height: 422px;
          -o-object-fit: cover;
          object-fit: cover;
          width: 422px;
          position: absolute;
          left: -14px;
          top: -14px;  
        }

        .onr__stock-photo {
          border-radius: 50%;
          height: 25em;
          object-fit: cover;
          width: 25em;
        }
      }
    }
  }

  &__testimony {
    &-title {
      color: black;
      font-size: 40px;
      margin: 0;
      padding-top: 40px;
      text-transform: uppercase;
    }

    &-subheading {
      margin-top: 5px;
    }
  }

  &__quote {
    background-color: #848484;
    height: 300px;
    margin: 200px 0 100px;

    &__text {
      color: white;
      font-size: 30px;
      font-family: Optima, sans-serif;
      padding: 100px 230px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 770px) {
  .onr-policy.onr-policy__panels {
    padding-top: 2rem;

    .onr-policy.onr-policy__block {
      margin: 0 2rem 5rem 2rem;

      &.onr-policy__panel {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
        width: 100%;

        .onr-policy.onr-policy__container__text {
          margin: 2rem;
        }

        .onr-policy.onr-policy__container__image {
          .onr__outline {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .onr-policy.onr-policy__panels {
    .onr__stock-photo {
      width: 14em;
      height: 14em;
    }
  }
}
</style>